import React from 'react';
import { Container } from 'react-bootstrap';
import { BenefitPanel } from './assets/compornents/BenefitPanel';
import { BenefitList } from './assets/compornents/BenefitPanel/benefit-list';

import ImageHeaderLogo from './assets/img/img-carmo-logo.svg';
import ImageHowToUseFirst from './assets/img/img-howto-first.svg';
import ImageHowToUseSecond from './assets/img/img-howto-second.svg';
import ImageHowToUseThird from './assets/img/img-howto-third.svg';
import ImageCampaign from './assets/img/img-campaign_20240301.png';
import ImageCampaignSp from './assets/img/img-banner-refer-sp-small.png';
import ImageCampaignPc from './assets/img/img-banner-refer-pc.png';
import ImageSnsTwitter from './assets/img/img-sns-twitter.png';
import ImageSnsYoutube from './assets/img/img-sns-youtube.png';
import ImageSnsYoutubeThumbnail from './assets/img/img-sns-thumbnail.png';
import ImageBannerMagazine from './assets/img/img-banner-magazine.png';
import IconFooterCall from './assets/img/img-footer-call.svg';
import IconFooterMail from './assets/img/icon-mail.svg';
import './layout.scss';
import { Helmet } from 'react-helmet-async';

export const Benefit = () => {
  const benefits = BenefitList;
  return (
    <>
      <Helmet>
        <title>カルモご契約者様だけの特別特典</title>
      </Helmet>

      <header>
        <h1 className="section-heading is-logo">
          <img
            className="section-heading-logo"
            src={ImageHeaderLogo}
            alt="おトクにマイカー 定額カルモくん"
          />
        </h1>
      </header>

      <Container>
        <section id="section-firstview">
          <div className="section-container is-firstview">
            <div className="firstview-heading-container">
              <h2>
                <span className="firstview-heading-sub">
                  ありがとうの気持ちを込めて！
                </span>
                <span className="firstview-heading-main">
                  カルモご契約者様だけの特別特典！
                </span>
              </h2>
            </div>
            <div className="firstview-visual-container"></div>
          </div>
          <div className="firstview-campaign-thumbnail-container">
            <figure className="firstview-campaign-thumbnail-container-item is-sp">
              <a
                href="https://carmo-kun.jp/lp/refer3/"
                target="_blank"
                rel="noopener"
              >
                <img
                  className="campaign-thumbnail-item"
                  src={ImageCampaignSp}
                  alt="カルモくん公式キャンペーン"
                />
              </a>
            </figure>
            <figure className="firstview-campaign-thumbnail-container-item is-pc">
              <a
                href="https://carmo-kun.jp/lp/refer3/"
                target="_blank"
                rel="noopener"
              >
                <img
                  className="campaign-thumbnail-item"
                  src={ImageCampaignPc}
                  alt="カルモくん公式キャンペーン"
                />
              </a>
            </figure>
          </div>
        </section>
        <section>
          <div className="section-container is-index">
            <div className="index-box">
              <p className="index-title">目次</p>
              <ul className="index-list">
                <li>
                  <a href="#section-howto" className="index-link-color">
                    1.特典の使い方
                  </a>
                </li>
                <li>
                  <a href="#section-list" className="index-link-color">
                    2.特典一覧
                  </a>
                </li>
                <li>
                  <a href="#section-campaign" className="index-link-color">
                    3.【2万円GET】紹介キャンペーン
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="section-container is-description">
            <p className="description">
              カーライフを豊かに、さらに日常を楽しんでほしい。
              <br />
              そんな想いと日頃の感謝の気持をこめて、カルモ契約者さま限定の特別特典をご用意しました。
              <br />
              クルマ関連はもちろん、おでかけや様々なシーンでぜひご活用ください。
            </p>
          </div>
        </section>

        <section id="section-howto">
          <div className="section-container is-howto">
            <h2 className="section-heading is-howto text-center position-relative">
              特典の使い方
            </h2>
            <ul className="howto-card-container d-flex flex-wrap">
              <li className="howto-card is-first">
                <div className="howto-image-container">
                  <img
                    className="howto-image-item"
                    src={ImageHowToUseFirst}
                    alt="ご希望の特典の利用方法をチェック！"
                  />
                </div>
                <p>ご希望の特典の利用方法をチェック！</p>
              </li>
              <li className="howto-card is-second">
                <div className="howto-image-container">
                  <img
                    className="howto-image-item"
                    src={ImageHowToUseSecond}
                    alt="ご希望の特典の利用方法をチェック！"
                  />
                </div>
                <p>「特典をつかう」を押して、提携企業サイトへ移動</p>
              </li>
              <li className="howto-card is-first">
                <div className="howto-image-container">
                  <img
                    className="howto-image-item"
                    src={ImageHowToUseThird}
                    alt="ご希望の特典の利用方法をチェック！"
                  />
                </div>
                <p>提携企業サイトの案内に従ってお申込み</p>
              </li>
            </ul>
            {/* <ul className="howto-notice-container">
              <li className="howto-notice-item">
                ※注意事項のテキスト注意事項のテキスト
              </li>
              <li className="howto-notice-item">
                ※注意事項のテキスト注意事項のテキスト注意事項のテキスト
              </li>
              <li className="howto-notice-item">
                ※注意事項のテキスト注意事項のテキスト
              </li>
              <li className="howto-notice-item">
                ※注意事項のテキスト注意事項のテキスト注意事項のテキスト
              </li>
              <li className="howto-notice-item">
                ※注意事項のテキスト注意事項のテキスト
              </li>
            </ul> */}
          </div>
        </section>

        <section id="section-list">
          <div className="section-container is-benefit-list">
            <h2 className="section-heading text-center position-relative">
              特典一覧
            </h2>
            <ul className="benefit-list-container">
              {benefits.map((row, index) => {
                return (
                  <BenefitPanel
                    benefit={row}
                    panel_no={index}
                    key={`benefit-${index}`}
                  />
                );
              })}
            </ul>
          </div>
        </section>

        <section id="section-campaign">
          <div className="section-container is-campaign">
            <h2 className="section-heading is-campaign">
              <span className="section-heading-subitem">契約者様限定！</span>
              <span className="section-heading-item">
                カルモくん公式キャンペーン
              </span>
            </h2>
            <div className="campaign-thumbnail-container">
              <figure>
                <a
                  href="https://carmo-kun.jp/lp/refer3/"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    className="campaign-thumbnail-item"
                    src={ImageCampaign}
                    alt="カルモくん公式キャンペーン"
                  />
                </a>
              </figure>
            </div>
          </div>
        </section>

        <section id="section-sns">
          <div className="section-container is-sns">
            <h2 className="section-heading text-center position-relative">
              SNSでも情報発信中！
            </h2>
            <div>
              <h3 className="section-subheading-sns">
                <img src={ImageSnsTwitter} alt="Twitter" />
              </h3>
              <div className="my-5 text-center">
                <a
                  className="twitter-timeline"
                  href="https://twitter.com/carmomycar?ref_src=twsrc%5Etfw"
                >
                  Tweets by carmomycar
                </a>
                <script
                  async
                  src="https://platform.twitter.com/widgets.js"
                ></script>
              </div>
            </div>

            <div>
              <h3 className="section-subheading-sns">
                <img src={ImageSnsYoutube} alt="Youtube" />
              </h3>
              <a className="link-youtube" href="https://youtu.be/IHy8gdLyqLY?si=a66ZObhDkxcWPzKO">
                <img src={ImageSnsYoutubeThumbnail} alt="Youtube" className='link-youtube-thumbnail' />
              </a>
            </div>
          </div>
        </section>

        <section id="section-magazine">
          <div className="section-container is-magazine">
            <h2 className="section-heading text-center position-relative">
              カルモマガジン
            </h2>
            <a
              href="https://car-mo.jp/"
              className="link-magazine"
              target="_blank"
              rel="noopener"
            >
              <img
                className="d-block w-100"
                src={ImageBannerMagazine}
                alt="クルマ選びとカーライフを応援するWebマガジン カルモマガジン"
              />
            </a>
          </div>
        </section>

        <footer className="section-benefit-footer is-benefit">
          <div className="footer-container">
            <h2 className="footer-heading">お問い合わせ</h2>
            <p className="footer-description">
              何かお困りごとがあれば、定額カルモくんサポートデスクにいつでもご連絡ください。
            </p>
            <p className="text-center mb-4">
              <a href="tel:0120-559-442">
                <img 
                  className="footer-tel-image"
                  src={IconFooterCall}
                  alt="0120-559-442"
                />
              </a>
              <span className="footer-phonetime">
                受付時間：平日10:00〜19:00（土日・祝日・年末年始休み）
              </span>
            </p>
            <p className="text-center mb-4">
              <a
                href="https://carmo-kun.jp/contact/"
                className="footer-link-mail"
                target="_blank"
                rel="noopener"
              >
                <span>
                  <img
                    src={IconFooterMail}
                    className="footer-link-mail-icon"
                    alt="メールフォームで相談する"
                  />
                </span>
                <span>メールフォームで相談する</span>
              </a>
            </p>
            <p className="text-center">
              <a
                href="/faq/"
                className="footer-link-qa"
                target="_blank"
                rel="noopener"
              >
                よくあるご質問はこちら
              </a>
            </p>
            <span className="d-block text-center">
              <small>&copy; 2018- Nyle Inc.</small>
            </span>
          </div>
        </footer>
      </Container>
    </>
  );
};
