import { dBenefit } from 'core/types/benefit';

export const BenefitList: dBenefit[] = [
  {
    id: 0,
    from: '2023/04/01',
    to: '2023/06/05',
    enterprise: '期間外の特典',
    service_name: '期間外の特典',
    detail: 'これは表示されないよ',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/img-dummy-benefit.png',
    link: '#',
  },
  {
    id: 1,
    from: '2023/04/01',
    to: '2023/9/30',
    enterprise: 'ソニー損保',
    service_name: '抽選キャンペーン',
    summary: '新規ご成約の方に抽選でプレゼント！',
    detail:
      '【キャンペーン期間：9月30日まで】\nソニー損保の保険料は「走る分だけ」！\n予想年間走行距離に応じて保険料を算出するので、休日だけお車を使用するという方、最近お車に乗る機会が少なくなった方には特におすすめです！\n\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/ソニー損保.png',
    link: 'http://brg.sonysonpo.co.jp/10057702/',
  },
  {
    id: 19,
    from: '2023/10/01',
    to: '2024/3/31',
    enterprise: 'ソニー損保',
    service_name: '抽選キャンペーン',
    summary: '新規ご成約の方に抽選でプレゼント！',
    detail:
      '【キャンペーン期間：2024年3月31日まで】\nソニー損保の保険料は「走る分だけ」！\n予想年間走行距離に応じて保険料を算出するので、休日だけお車を使用するという方、最近お車に乗る機会が少なくなった方には特におすすめです！\n\nナイル株式会社の経営方針により損害保険会社を選定し、ご案内しております。\n\n※特典は、「抽選に応募できる」ことを指します。\n\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/ソニー損保_20231001.png',
    link: 'http://brg.sonysonpo.co.jp/10057702/',
  },
  {
    id: 2,
    from: '2023/01/01',
    to: '9999/12/31',
    enterprise: 'SBI損保',
    service_name: '自動車保険新規ご契約特典',
    summary: '豪華グッズが当たるキャンペーン中！',
    detail:
      'SBI損保の自動車保険が選ばれる3つの理由\nリーズナブルな保険料・業界最高基準※1のロードサービス・24時間365日事故受付\nインターネットからの新規お申し込みで保険料14,500円割引！※2\n\n\n引受保険会社：SBI損害保険株式会社(23-0072-12-001)\n※1 2023年2月SBI損保調べ。\n※2 ①インターネット割引(14,000円) ②証券不発行(500円)を適用した割引額です。\n月払の場合は年間14,520円(①14,040円②480円)となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/SBI損害保険.jpg',
    link: 'https://www.sbisonpo.co.jp/cha/nyle/',
  },
  {
    id: 3,
    from: '2023/07/03',
    to: '2024/3/31',
    enterprise: '楽天Car車検',
    service_name: '最大3,500pt',
    summary: 'エントリー＆車検実施でもらえる！',
    detail:
      '楽天Car車検でオトクに車検しよう！楽天ポイント3,500ポイントが獲得できて、さらにガソリン値引きなどのネット特典もアリ！口コミ評価平均も4.5/5.0だから安心して任せられます。',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/楽天car車検.jpg',
    link: 'https://car.rakuten.co.jp/shaken/campaign/carmo-kun/3500p_jun23/',
  },
  {
    id: 13,
    from: '2024/03/01',
    to: '2025/2/28',
    enterprise: '楽天Car洗車コーティング',
    service_name: '最大1,000pt',
    summary: 'コーティング実施でも楽天ポイント貯まってお得',
    detail:
      'カーコーティングの比較・来店予約サイト「楽天Car洗車・コーティング」を通して予約・実施することで、話題のコーティングもネット割価格でお得にでき、利用金額に応じた楽天ポイント（利用金額の1～5％分のポイント）も獲得できます。また、カルモ会員様ならさらに最大1,000ポイントを獲得（要エントリー、利用条件あり）できるので、話題のキーパーコーティングも大変お得に実施できます！',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/楽天car車検_洗車コーティング.jpg',
    link: 'https://car.rakuten.co.jp/carwash/campaign/carmo-kun/add1000_mar24/',
  },
  {
    id: 16,
    from: '2024/02/01',
    to: '2025/01/31',
    enterprise: '楽天Carキズ修理',
    service_name: '車のキズ修理',
    summary: '実施で楽天ポイント獲得',
    detail:
      '楽天Carキズ修理のサイトでは、画像診断実施中！キズ写真を添付するだけで修理の概算が分かります。さらに！予約店舗でキズ修理を実施すると、実施金額に応じた楽天ポイントとキャンペーンポイントが獲得できます。',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/楽天Carキズ修理.jpg',
    link: 'https://car.rakuten.co.jp/repair/campaign/carmo-kun/1000p_feb24/',
  },
  {
    id: 4,
    from: '2023/12/01',
    to: '2023/03/31',
    enterprise: 'エビス光・電気',
    service_name: 'セットでお得',
    summary: '最大7万円分の特典あり',
    detail:
      'カルモくん契約者限定で、エビス光をご契約いただくと最大6万円相当の特典がもらえるキャンペーンが始まりました。さらに、エビス電気をセットでご契約いただくと最大7万円相当の特典がもらえます。※お住まいの地域や建物によっては導入出来ない場合があります。',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/エビス電気.png',
    link: 'https://ebisuhikari.hp.peraichi.com/',
  },
  {
    id: 5,
    from: '2023/06/01',
    to: '2024/5/22',
    enterprise: 'akippa',
    service_name: 'カルモ会員様限定 10%OFFクーポン',
    summary: '簡単に駐車場予約＆おトクに利用',
    detail:
      'オンラインで駐車場予約ができるサービス「akippa」のカルモ契約者限定クーポンのご案内です。akippaでは全国に常時35,000件以上の豊富な駐車場が事前予約、事前決済できるアプリです。※2023年05月時点\n\nakippaの駐車場は空きスペースを有効活用しているため、コインパーキングより安価な料金設定でおトクです。行楽地やイベント会場での駐車場を探す手間が省け、ドライブがもっと楽しくなるakippaを、この機会にぜひご利用ください。\n\n ■ご利用上の注意\n・1日、15分単位両方の予約で使用出来ます。\n・利用回数は最大100回まで、最大適応日数は30日となります。\n・サービス料には適用されません。\n\n■クーポン発行の流れ\n・akippa未登録の方：ログイン画面より登録いただき、再度ログインしてください。自動的にクーポン取得となります。\n・akippa登録済みの方：URLへアクセスいただくだけでクーポン自動取得となります。\n（※ログイン情報を端末に保存していた場合）\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/akippa.png',
    link: 'https://www.akippa.com/?coupon_code=7fe92d93a75087d74cb31ea50e52d88b',
  },
  {
    id: 6,
    from: '2023/07/01',
    to: '9999/12/31',
    enterprise: 'VEEMO',
    service_name: '初回登録で500pt',
    summary: 'カンタン、お得な駐車場検索アプリ',
    detail:
      'プロモーションコード：【carmo500】を入れてすぐ500ポイントゲット！さらに「駐車場レシート買取キャンペーン」応募でレシート1枚10VEEMOポイントのところ、カルモ契約者なら2倍の20VEEMOポイントもらえる！\n※リンク先はスマホやタブレットのアプリダウンロードページになります。パソコン等では開けませんので、予めご了承ください。',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/VEEMO.png',
    link: 'https://app.veemo.jp/app_store',
  },
  {
    id: 7,
    from: '2023/04/01',
    to: '2023/09/30',
    enterprise: 'レジャパス！',
    service_name: 'プレミアムプラン',
    summary: '月額3,980円（税別）が初月無料！',
    detail:
      '全国200以上の観光施設を定額で利用できる「レジャパス」の特別特典。\n温泉・レジャー・エンタメなどで好きなだけ遊べて楽しみ放題、癒され放題。\nカルモ契約者なら、クーポンコードをコードを入れるだけで全てのプランが初月無料！\n\nクーポンコード：carmokun\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/レジャパス_プレミアム.png',
    link: 'https://lp.lejapass.com/2023main1/',
  },
  {
    id: 8,
    from: '2023/04/01',
    to: '2023/09/30',
    enterprise: 'レジャパス！',
    service_name: 'スタンダードプラン',
    summary: '月額2,980円（税別）が初月無料！',
    detail:
      '全国200以上の観光施設を定額で利用できる「レジャパス」の特別特典。\n温泉・レジャー・エンタメなどで好きなだけ遊べて楽しみ放題、癒され放題。\nカルモ契約者なら、クーポンコードをコードを入れるだけで全てのプランが初月無料！\n\nクーポンコード：carmokun2\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/レジャパス_スタンダード.png',
    link: 'https://lp.lejapass.com/2023main1/',
  },
  {
    id: 9,
    from: '2023/04/01',
    to: '2023/09/30',
    enterprise: 'レジャパス！',
    service_name: 'ライトプラン',
    summary: '月額1,980円（税別）が初月無料！',
    detail:
      '全国200以上の観光施設を定額で利用できる「レジャパス」の特別特典。\n温泉・レジャー・エンタメなどで好きなだけ遊べて楽しみ放題、癒され放題。\nカルモ契約者なら、クーポンコードをコードを入れるだけで全てのプランが初月無料！\n\nクーポンコード：carmokun3\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/レジャパス_ライト.png',
    link: 'https://lp.lejapass.com/2023main1/',
  },
  {
    id: 10,
    from: '2023/04/01',
    to: '2023/09/30',
    enterprise: 'セイビー',
    service_name: '出張12ヶ月点検1,000円オフ',
    summary: '限定クーポン発行中',
    detail:
      '「車の点検をしてほしい」「最近車の調子が悪い」そんな方にお勧めです！\n持ち込み不要、待ち時間0分の車の出張修理・整備サービス「セイビー」のサービス※が、カルモ契約者限定で1,000円オフとなるキャンペーン実施中！\n\n▼クーポン利用方法\n①「特典を使う」からサイトに移動\n②メニューを選択\n③新規登録／ログイン\n④情報入力ページにてクーポンコード【CARMOSE23】を入力\n以上で1,000円オフが適応されます。\n※クーポンコードの英語、CARMOSEは大文字での入力をお願い致します。\n\nカルモのメンテナンスプランご加入の方へ\n一部メニューに関しては、ご加入のプランにより上限回数・上限金額内でキャッシュバックが可能です。\nメンテナンスプランの内容をご確認ください。\n\n※ご利用上の注意※\nクーポンコード利用期限：2023年9月末日\n予約をする際にクーポンコードが未記入の場合、後からの割引適応は不可となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/セイビー_12か月点検.png',
    link: 'https://seibii.co.jp/services/statutory_inspection',
  },
  {
    id: 11,
    from: '2023/04/01',
    to: '2023/09/30',
    enterprise: 'セイビー',
    service_name: '出張バッテリー交換1,000円オフ',
    summary: '限定クーポン発行中',
    detail:
      'クルマのバッテリー交換を出張で！作業実績60,000件以上！輸入車も対応可！\n持ち込み不要、待ち時間0分の車の出張修理・整備サービス「セイビー」のサービス※が、カルモ契約者限定で1,000円オフとなるキャンペーン実施中！\n\n▼クーポン利用方法\n①「特典を使う」からサイトに移動\n②メニューを選択\n③新規登録／ログイン\n④情報入力ページにてクーポンコード【CARMOSE23】を入力\n以上で1,000円オフが適応されます。\n※クーポンコードの英語、CARMOSEは大文字での入力をお願い致します。\n\nカルモのメンテナンスプランご加入の方へ\n一部メニューに関しては、ご加入のプランにより上限回数・上限金額内でキャッシュバックが可能です。\nメンテナンスプランの内容をご確認ください。\n\n※ご利用上の注意※\nクーポンコード利用期限：2023年9月末日\n予約をする際にクーポンコードが未記入の場合、後からの割引適応は不可となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/セイビー_バッテリー交換.png',
    link: 'https://seibii.co.jp/services/battery',
  },
  {
    id: 12,
    from: '2023/04/01',
    to: '2023/09/30',
    enterprise: 'セイビー',
    service_name: '出張ドラレコ取り付け1,000円オフ',
    summary: '限定クーポン発行中',
    detail:
      'ドライブレコーダーを出張でお取り付け！作業実績60,000件以上！配線は見えない形でプロがキレイにお取り付けいたします。輸入車も対応可！\n持ち込み不要、待ち時間0分の車の出張修理・整備サービス「セイビー」のサービス※が、カルモ契約者限定で1,000円オフとなるキャンペーン実施中！\n\n▼クーポン利用方法\n①「特典を使う」からサイトに移動\n②メニューを選択\n③新規登録／ログイン\n④情報入力ページにてクーポンコード【CARMOSE23】を入力\n以上で1,000円オフが適応されます。\n※クーポンコードの英語、CARMOSEは大文字での入力をお願い致します。\n\nカルモのメンテナンスプランご加入の方へ\n一部メニューに関しては、ご加入のプランにより上限回数・上限金額内でキャッシュバックが可能です。\nメンテナンスプランの内容をご確認ください。\n\n※ご利用上の注意※\nクーポンコード利用期限：2023年9月末日\n予約をする際にクーポンコードが未記入の場合、後からの割引適応は不可となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/セイビー_前後ドラレコ.png',
    link: 'https://seibii.co.jp/services/driverecorder',
  },
  {
    id: 20,
    from: '2023/10/01',
    to: '2024/03/31',
    enterprise: 'セイビー',
    service_name: '限定クーポン発行中',
    summary: '出張バッテリー交換1,000円オフ',
    detail:
      'クルマのバッテリー交換を出張で！作業実績60,000件以上！輸入車も対応可！\n持ち込み不要、待ち時間0分の車の出張修理・整備サービス「セイビー」のサービス※が、カルモ契約者限定で1,000円オフとなるキャンペーン実施中！\n\n▼クーポン利用方法\n①「特典を使う」からサイトに移動\n②メニューを選択\n③新規登録／ログイン\n④情報入力ページにてクーポンコード【CARMOSE23】を入力\n以上で1,000円オフが適応されます。\n※クーポンコードの英語、CARMOSEは大文字での入力をお願い致します。\n\nカルモのメンテナンスプランご加入の方へ\n一部メニューに関しては、ご加入のプランにより上限回数・上限金額内でキャッシュバックが可能です。\nメンテナンスプランの内容をご確認ください。\n\n※ご利用上の注意※\nクーポンコード利用期限：2024年3月末日\n予約をする際にクーポンコードが未記入の場合、後からの割引適応は不可となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/セイビー_バッテリー交換.png',
    link: 'https://seibii.co.jp/services/battery',
  },
  {
    id: 21,
    from: '2023/10/01',
    to: '2024/03/31',
    enterprise: 'セイビー',
    service_name: '限定クーポン発行中',
    summary: '出張ドラレコ取り付け1,000円オフ',
    detail:
      'ドライブレコーダーを出張でお取り付け！作業実績60,000件以上！配線は見えない形でプロがキレイにお取り付けいたします。輸入車も対応可！\n持ち込み不要、待ち時間0分の車の出張修理・整備サービス「セイビー」のサービス※が、カルモ契約者限定で1,000円オフとなるキャンペーン実施中！\n\n▼クーポン利用方法\n①「特典を使う」からサイトに移動\n②メニューを選択\n③新規登録／ログイン\n④情報入力ページにてクーポンコード【CARMOSE23】を入力\n以上で1,000円オフが適応されます。\n※クーポンコードの英語、CARMOSEは大文字での入力をお願い致します。\n\nカルモのメンテナンスプランご加入の方へ\n一部メニューに関しては、ご加入のプランにより上限回数・上限金額内でキャッシュバックが可能です。\nメンテナンスプランの内容をご確認ください。\n\n※ご利用上の注意※\nクーポンコード利用期限：2024年3月末日\n予約をする際にクーポンコードが未記入の場合、後からの割引適応は不可となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/セイビー_前後ドラレコ.png',
    link: 'https://seibii.co.jp/services/driverecorder',
  },
  {
    id: 22,
    from: '2023/10/01',
    to: '2024/03/31',
    enterprise: 'セイビー',
    service_name: '限定クーポン発行中',
    summary: 'スタッドレスタイヤ履き替え1回利用1000円OFF',
    detail:
      'クルマのスタッドレスタイヤ履き替えを出張で自宅で交換可能！作業実績60,000件以上！輸入車も対応可！\n持ち込み不要、待ち時間0分の車の出張修理・整備サービス「セイビー」のサービス※が、カルモ契約者限定で1,000円オフとなるキャンペーン実施中！\n\n▼クーポン利用方法\n①「特典を使う」からサイトに移動\n②メニューを選択\n③新規登録／ログイン\n④情報入力ページにてクーポンコード【CARMOSE23】を入力\n以上で1,000円オフが適応されます。\n※クーポンコードの英語、CARMOSEは大文字での入力をお願い致します。\n\nカルモのメンテナンスプランご加入の方へ\n一部メニューに関しては、ご加入のプランにより上限回数・上限金額内でキャッシュバックが可能です。\nメンテナンスプランの内容をご確認ください。\n\n※ご利用上の注意※\nクーポンコード利用期限：2024年3月末日\n予約をする際にクーポンコードが未記入の場合、後からの割引適応は不可となります。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/セイビー_スタッドレスタイヤ.png',
    link: 'https://seibii.co.jp/services/tyre_change_w_wheel',
  },
  {
    id: 14,
    from: '2023/12/1',
    to: '2024/3/31',
    enterprise: 'アソビュー',
    service_name: '3%オフクーポン',
    summary: '何回でも遊びの予約をおトクに',
    detail:
      '日本全国の遊びを便利でお得に予約できるサービス「アソビュー」で使えるクーポンです！\n夏に人気のSUPやダイビング、スパや日帰り温泉など、遊びの種類620以上！施設数はなんと10,470施設以上のラインナップ。\n日々の遊びを考える際にはぜひ簡単に検索、選ぶ、予約ができるアソビューをご活用ください。\nクーポンコード：u7m2p\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/アソビュー.png',
    link: 'https://www.asoview.com/',
  },
  {
    id: 15,
    from: '2023/08/01',
    to: '2023/12/31',
    enterprise: 'オリックスレンタカー',
    service_name: '特割15',
    summary: 'お得なレンタカープラン',
    detail:
      'オリックスレンタカーでは、おトクにマイカー定額カルモくん会員さま限定で、通常のご利用に比べてお得にご利用いただけるレンタカープランをご用意いたしました。ぜひオリックスレンタカーをご利用ください。\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/オリックスレンタカー.jpg',
    link: 'https://car.orix.co.jp/campaigns_page/?campk=1893',
  },
  {
    id: 17,
    from: '2023/08/17',
    to: '2099/12/31',
    enterprise: 'subsclife(ｻﾌﾞｽｸﾗｲﾌ)',
    service_name: '8%OFFクーポン',
    summary: '人気のデザイナーズ家具・ブランド家電をお得に利用',
    detail:
      'お支払い総額が定価を超えない、新品家具・家電のサブスクサービス「subsclife（サブスクライフ）」のカルモ契約者限定クーポンのご案内です。\n\n\nライフスタイルに合わせて、月額500円から始められる家具・家電のサブスクサービス\n\n■subsclifeの3つのポイント\n・お支払い総額が定価を超えない\n・新品デザイナーズ家具・ブランド家電を一点から利用可能\n・月額払いでお財布に優しく、購入も可能\n\n月額払いで理想のお部屋づくりをもっとお手軽に！\nsubsclifeを、この機会にぜひご利用ください。\n\nクーポンコード：carmospC2308\n\n※クーポンご利用上の注意\n・月額の合計が2,000円以上のお申込にご利用可能\n・1アカウント1回まで\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/サブスクライフ.png',
    link: 'https://subsclife.com/?utm_source=other&utm_medium=other&utm_campaign=2308-app-nyl',
  },
  {
    id: 18,
    from: '2023/09/14',
    to: '2099/12/31',
    enterprise: '子供靴のサブスク「Kutoon」',
    service_name: '初月無料！',
    summary: 'カルモ契約者はさらに2ヶ月目も30%off',
    detail:
      'すぐにサイズが変わる子供靴の悩み、Kutoonの定額レンタルで解消しませんか？\n\n\nラインナップは有名メーカの靴が中心！\n\n■Kutoonの3つのポイント\n①家にいながら毎月新しい靴が履ける\n②下駄箱に靴が増えない\n③こども靴を洗わなくてよくなる\n\n■選べるプランは3種類！初月無料＆カルモ契約者は2ヶ月目も30%オフ！\n・まずは気軽に、手元に1足プラン　月額2,480円\n・一番人気の、手元に2足プラン　月額2,980円\n・兄弟姉妹でつかえる、手元に3足プラン　月額3,700円\n\n■クーポン使用方法\n①気になるプランの「無料で試す」をクリック\n②会員登録をする\n③お申込み画面でクーポンコードを入れる\nクーポンコード：car-mo30\n\n※ご利用上の注意\n・退会はいつでも可能です。\n・カルモ契約者の2ヶ月目30%オフ特典を利用する際には必ずクーポンコードを記入してください。\n・初月無料キャンペーンはみなさま自動的に適応されます。\n・初月無料キャンペーンは、やむを得ない事情により中止・変更となる場合があります。\n\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/Kutoon.png',
    link: 'https://kutoon.net/',
  },
  {
    id: 23,
    from: '2023/11/1',
    to: '2099/12/31',
    enterprise: 'ハート引越センター',
    service_name: '引越料金30%OFF！',
    summary: '割引など各種特典をご用意！',
    detail:
      '引越し料金の見積もり、単身引越しのご相談ならハート引越センター　プロの引越し業者にお任せ\n\n\n北海道から沖縄まで全国直営、独自の物流システムで近距離はもちろん長距離引越も安心、納得のサービスをご提供いたします。荷造り荷解き、電気工事やハウスクリーニングなどもお気軽にご相談ください\n\n■　カルモ契約者特典\n①基本料金より30%オフ！※繁忙期間3/15~4/10は除く\n②梱包資材各種プレゼント（ダンボール単身10枚、家族50枚・ガムテープ必要数・ハンガーBOX必要数レンタル無料）\n\n■　クーポン使用方法は２つ\n①ハート引越センター「0120-810-321」へお電話のうえ「定額カルモくんの契約者」とお伝えください。\n②専用サイトからWEB見積依頼（必ず、下記の「特典をつかう」ボタンからお申し込みください）\n\n※ご利用上の注意\n30%オフ特典を利用する際には必ずクーポン使用方法に従ってください。\nキャンペーンは、やむを得ない事情により中止・変更となる場合があります。\n\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/ハート引っ越しセンター.jpg',
    link: 'https://www.hikkoshi8100.com/?media_type=165',
  },
  {
    id: 24,
    from: '2023/11/28',
    to: '2099/12/31',
    enterprise: 'アクティビティジャパン',
    service_name: '限定クーポン発行中',
    summary: 'アクティビティ、体験の予約をおトクに！',
    detail:
      '日本全国のアクティビティ、体験を便利でお得に予約できるサービス「アクティビティジャパン」で使えるクーポンです！\n\n\n夏に人気のシュノーケリングやSUP、ダイビングなどアウトドアアクティビティ・遊び・体験・レジャーを日本全国400種目、18,000プラン以上の中から格安予約！\nおでかけにはぜひ簡単に検索、予約ができるアクティビティジャパンをご活用ください。\n\nクーポンコード：CAKN\n\n',
    img_url:
      'https://carmo-public-contents.s3.ap-northeast-1.amazonaws.com/benefit/アクティビティジャパン.jpg',
    link: 'https://activityjapan.com/',
  },
];
